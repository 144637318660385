import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState, useEffect } from "react";
import { Playground, Props } from 'docz';
import { PackageMetadata, BackgroundBox } from '@happeo/docs-tools';
import pkg from "../package.json";
import { Spinner, Loader, LoadingContainer, LoadingModal } from '../src/index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "loaders"
    }}>{`Loaders`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
    <h2 {...{
      "id": "loader"
    }}>{`Loader`}</h2>
    <Props of={Loader} mdxType="Props" />
    <h3 {...{
      "id": "default"
    }}>{`Default`}</h3>
    <Playground __position={2} __code={'Small\n<Loader />\nMedium\n<Loader size=\"medium\" />\nLarge\n<Loader size=\"large\" />'} __scope={{
      props,
      DefaultLayout,
      useState,
      useEffect,
      Playground,
      Props,
      PackageMetadata,
      BackgroundBox,
      pkg,
      Spinner,
      Loader,
      LoadingContainer,
      LoadingModal,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    Small
    <Loader mdxType="Loader" />
Medium
      <Loader size='medium' mdxType="Loader" />

Large
      <Loader size='large' mdxType="Loader" />
    </Playground>
    <h3 {...{
      "id": "default-1"
    }}>{`Default`}</h3>
    <BackgroundBox mdxType="BackgroundBox">
       Small
       <Loader light mdxType="Loader" />
   Medium
   <Loader light size='medium' mdxType="Loader" />

   Large
   <Loader light size='large' mdxType="Loader" />
    </BackgroundBox>
    <h2 {...{
      "id": "spinner"
    }}>{`Spinner`}</h2>
    <p>{`Spinner is meant to be used as a standalone spinner, with a loader content or with loader modal.
It has three different states: "loading", "success", "error". The transition between this states
are animated from "loading" > "success" and "loading" > "error". `}</p>
    <Props of={Spinner} mdxType="Props" />
    <Playground __position={5} __code={'() => {\n  const [loadingState, setLoadingState] = useState(\'loading\')\n  const [progress, setProgress] = useState(0)\n  useEffect(() => {\n    function changeStateRandomly() {\n      setProgress(prevValue => {\n        const val = prevValue >= 100 ? 0 : prevValue\n        return Math.min(100, val + Math.random() * 50)\n      })\n      if (loadingState === \'loading\')\n        setLoadingState(Math.random() > 0.5 ? \'success\' : \'error\')\n      else setLoadingState(\'loading\')\n    }\n    const interval = setInterval(changeStateRandomly, 3000)\n    return () => clearInterval(interval)\n  }, [loadingState])\n  return (\n    <>\n      Default\n      <Spinner state={loadingState} />\n      Small (24px + 4px Border)\n      <Spinner state={loadingState} sizePx={24} />\n      Smaller (10px + 3px Border)\n      <Spinner state={loadingState} sizePx={10} weightPx={3} />\n      Default with progress\n      <Spinner state={\'progress\'} progress={progress} />\n      Default with progress and percentage\n      <Spinner state={\'progress\'} progress={progress} showPercentage={true} />\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      useEffect,
      Playground,
      Props,
      PackageMetadata,
      BackgroundBox,
      pkg,
      Spinner,
      Loader,
      LoadingContainer,
      LoadingModal,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    {() => {
        const [loadingState, setLoadingState] = useState("loading");
        const [progress, setProgress] = useState(0);
        useEffect(() => {
          function changeStateRandomly() {
            setProgress(prevValue => {
              const val = prevValue >= 100 ? 0 : prevValue;
              return Math.min(100, val + Math.random() * 50);
            });
            if (loadingState === "loading") setLoadingState(Math.random() > 0.5 ? "success" : "error");else setLoadingState("loading");
          }
          const interval = setInterval(changeStateRandomly, 3000);
          return () => clearInterval(interval);
        }, [loadingState]);
        return <>
                    
                    Default
                    <Spinner state={loadingState} mdxType="Spinner" />
                    Small (24px + 4px Border)
                    <Spinner state={loadingState} sizePx={24} mdxType="Spinner" />
                    Smaller (10px + 3px Border)
                    <Spinner state={loadingState} sizePx={10} weightPx={3} mdxType="Spinner" />
                    Default with progress
                    <Spinner state={"progress"} progress={progress} mdxType="Spinner" />
                    Default with progress and percentage
                    <Spinner state={"progress"} progress={progress} showPercentage={true} mdxType="Spinner" />
                </>;
      }}
    </Playground>
    <h2 {...{
      "id": "loadingcontainer"
    }}>{`LoadingContainer`}</h2>
    <p>{`LoadingContainer is meant to be used in modal actions or elsewhere where it makes more sense not to use
LoadingModal. `}</p>
    <Props of={LoadingContainer} mdxType="Props" />
    <Playground __position={7} __code={'<LoadingContainer\n  title={\'So much title\'}\n  description={\'This loader will load for ever and ever and ever...\'}\n/>'} __scope={{
      props,
      DefaultLayout,
      useState,
      useEffect,
      Playground,
      Props,
      PackageMetadata,
      BackgroundBox,
      pkg,
      Spinner,
      Loader,
      LoadingContainer,
      LoadingModal,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <LoadingContainer title={"So much title"} description={"This loader will load for ever and ever and ever..."} mdxType="LoadingContainer" />
    </Playground>
    <h2 {...{
      "id": "loadingmodal"
    }}>{`LoadingModal`}</h2>
    <p>{`LoadingModal is meant to be used when a full loader is needed and loading time is long.`}</p>
    <Props of={LoadingModal} mdxType="Props" />
    <Playground __position={9} __code={'() => {\n  const [modalIsOpen, setModalIsOpen] = useState(false)\n  return (\n    <div style={{ padding: \'32px\' }}>\n      <button onClick={() => setModalIsOpen(true)}>Open modal</button>\n      <LoadingModal\n        isOpen={modalIsOpen}\n        title={\'Hahaa! You cannot close me\'}\n        description={\'You fool! You need to refresh this page now. Hahaha!\'}\n        state={\'loading\'}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      useEffect,
      Playground,
      Props,
      PackageMetadata,
      BackgroundBox,
      pkg,
      Spinner,
      Loader,
      LoadingContainer,
      LoadingModal,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    {() => {
        const [modalIsOpen, setModalIsOpen] = useState(false);
        return <div style={{
          padding: "32px"
        }}>
                    <button onClick={() => setModalIsOpen(true)}>Open modal</button>
                    <LoadingModal isOpen={modalIsOpen} title={"Hahaa! You cannot close me"} description={"You fool! You need to refresh this page now. Hahaha!"} state={"loading"} mdxType="LoadingModal" />
                </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      